<template>
  <div id="app">
    <RuntimeTemplateCompiler :template="data.html"/>
  </div>
</template>

<script>


import {RuntimeTemplateCompiler} from 'vue-runtime-template-compiler'

export default {
  name: 'TestHtmlTable.vue',
  components: {
    RuntimeTemplateCompiler
  },
  props: {
    data: Object
  },
  data() {
    return {}
  },
  computed: {
    t() {
      return this.data.data
    }
  }
}
</script>

<style lang="stylus" scoped>
>>> table {
  border-top 1px solid #dcdcdc;
  border-left 1px solid #dcdcdc;
  width 100%
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 0;
  table-layout:fixed;
  word-wrap: break-word;
}

>>> th, >>> td {
  background #f7f7f7;
  border-bottom 1px solid #dcdcdc;
  border-right 1px solid #dcdcdc;
  height: 22px;
  color #666
  padding 10px
  font-size: 14px;
}
>>> thead{
  td,th{
    color: #7f9bc9;
  }
}
>>> th {
  color: #7f9bc9;
}
</style>
